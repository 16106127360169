import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GoTo = makeShortcode("GoTo");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "encourage-a-healthy-diet"
    }}>{`Encourage a healthy diet`}</h1>
    <h3 {...{
      "id": "what-should-my-teenager-eat"
    }}>{`What should my teenager eat?`}</h3>
    <p>{`Teenagers should eat a wide variety of nutritious foods from the five food groups: (1) Fruits and vegetables, (2) Potatoes, bread, rice, pasta and other starchy carbohydrates, (3) Beans, pulses, fish, eggs, meat and other proteins, (4) Dairy and alternatives, and (5) Oils and spreads. `}<GoTo external={true} to="https://www.eatforhealth.gov.au/guidelines/australian-guide-healthy-eating" mdxType="GoTo">{`Click here`}</GoTo>{` to find out more (you can also `}<GoTo external={true} to="https://www.eatforhealth.gov.au/sites/default/files/content/The%20Guidelines/n55_agthe_large.pdf" mdxType="GoTo">{`click here`}</GoTo>{` to download a copy of this chart).`}</p>
    <h3 {...{
      "id": "how-much-should-my-teenager-eat"
    }}>{`How much should my teenager eat?`}</h3>
    <p>{`This varies from person to person and depends on their age. `}<GoTo external={true} to="https://www.eatforhealth.gov.au/food-essentials/how-much-do-we-need-each-day" mdxType="GoTo">{`Click here for more information`}</GoTo>{`.`}</p>
    <h3 {...{
      "id": "what-foods-should-my-teenager-limit"
    }}>{`What foods should my teenager limit?`}</h3>
    <p>{`Limit foods containing:`}</p>
    <ul>
      <li parentName="ul">{`Added salt.`}</li>
      <li parentName="ul">{`Added sugar, e.g. lollies, soft drinks, cordial, fruit drinks and sports drinks.`}</li>
      <li parentName="ul">{`Saturated fats, e.g. cakes, biscuits, pastries, pizza, fried foods, processed meats, burgers and chips.`}</li>
    </ul>
    <p>{`Research`}<sup>{`1,2,3`}</sup>{` shows that:`}</p>
    <p>{`Eating a lot of processed foods (e.g. chips, soft drinks, hamburgers) can negatively affect mood.`}</p>
    <p>{`26.6% of young Australians are overweight or obese. This can leave the young person feeling bad about themselves and increase their risk of depression and clinical anxiety.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m7/32.svg" alt="friends hero image" mdxType="SingleImage" />
    <sup>
  1,2,3. See <GoTo to="/appendix" mdxType="GoTo">Appendix</GoTo> page for full reference.
    </sup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      